import Weather from "./Weather.js";
import React from "react";

export default function Start() {
  return (
    <div className="App">
      <header className="App-header">
        <div6>
          <Weather />
        </div6>
      </header>
    </div>
  );
}
